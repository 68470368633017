import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import {
  Section,
  BreadCrumb,
  GradientWrapper,
  SectionTitle,
  SectionPageTitle,
  DotGradiantCircle,
} from "../components/Section";
import {
  ArrowRight,
  HomeIcon,
  EnvelopIconSingleColor,
  TwitterIcon,
  LinkedInIcon,
  ArrowLineIcon,
} from "../components/Icons";
import Image from "../components/image";
import Img from "gatsby-image";
import PaymentCard from "../components/PaymentCard";
import SecondaryLinkButton from "../components/Button/SecondaryLinkButton";
import BreakpointDown from "../components/Media/BreakpointDown";
const BorderTop = styled(Section)`
  border-top: 1px solid #cbd1e2;
`;
const BorderBox = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  &:before,
  &:after {
    content: "";
    flex: 1;
    height: 4px;
  }
  &:before {
    background: #223c7e;
  }
  &:after {
    background: #f36224;
  }
`;

const DotGradiantCircleBototmLeft = styled(DotGradiantCircle)`
  bottom: -42px;
  top: auto;
  left: -42px;
`;
const DotGradiantCircleBototmRight = styled(DotGradiantCircle)`
  bottom: -38px;
  top: auto;
  right: -38px;
  left: auto;
`;
const DotGradiantCircleTopRight = styled(DotGradiantCircle)`
  top: -38px;
  right: 15px;
  left: auto;
`;
const DotGradiantCircleTopRight1 = styled(DotGradiantCircleTopRight)`
  right: -40px;
`;

const TopWrapper = styled.div`
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -200px;
  position: relative;
  z-index: 1;
  ${BreakpointDown.xl`
     margin-bottom: -132px;
  `}
`;

const PageHeaiding = styled(SectionPageTitle)`
  margin-top: 0;
  margin-bottom: 35px;
  margin-top: 15px;
  @media (min-width: 1280px) {
    font-size: 42px;
  }
  ${BreakpointDown.sm`
    margin-bottom: 15px;
  `}
`;
const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const CardWrapperItem = styled.div`
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.xl`
   flex: 1 1 315px;
   max-width:315px;
 `}
  ${BreakpointDown.md`
 margin-bottom:35px;
 flex: 1 1 50%;
   max-width:50%;
`}
`;
const CardWrapperItemImg = styled.div`
  position: relative;
  width: 100%;
  max-width: 220px;
  &:last-of-type {
    ${BreakpointDown.lg`
    max-width:110px; 
 `}
  }
  ${BreakpointDown.lg`
   max-width:180px; 
 `}
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  ${BreakpointDown.sm`
   margin-top:10px;
   margin-bottom:10px;
`}
`;
const MarcusHistory = styled.div`
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
  ${BreakpointDown.lg`
    // flex-direction:column-reverse;
    .img-wrapper{
        top:100px;
        position:sticky;
    }
    `}
  ${BreakpointDown.sm`
     flex-direction:column-reverse;
    `}
   .img-wrapper {
    margin-left: auto;
    ${BreakpointDown.sm`
        margin-right:0;
        margin-bottom:20px;
       `}
  }
  &:nth-child(even) {
    flex-direction: row-reverse;
    margin-top: 80px;
    ${BreakpointDown.sm`
       flex-direction:column-reverse;
       margin-top:20px;
     `}
    .img-wrapper {
      margin-right: auto;
    }
  }
`;
const HistoryContentWrapper = styled.div`
  max-width: 65%;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.sm`
     max-width:100%;
     width:100%;
  `}

  p {
    & + p {
      margin-top: 40px;
      ${BreakpointDown.sm`
         margin-top:0;
      `}
    }
  }
`;
const HistoryImgWrapper = styled.div`
  max-width: 35%;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  ${BreakpointDown.sm`
     max-width:100%;
     width:100%;
  `}
`;

const HeaidingImg = styled.div`
  position: relative;
  width: 100%;
  max-width: 420px;
  ${BreakpointDown.sm`
    margin-top:20px;
  `}
`;

const HistoryTimeLine = styled.div`
  position: relative;
`;
const TimeLineItem = styled.div`
  display: flex;
  margin-top: 30px;
  position: relative;
  ${BreakpointDown.sm`
   margin-top:0px;
 `}
  &:nth-child(even) {
    span {
      background: #f36224;
    }
    &:last-child {
    }
  }
`;
const Date = styled.div`
  margin-right: 40px;
  margin-top: 8px;
  flex: 1 1 75px;
  position: relative;
  z-index: 2;
  ${BreakpointDown.sm`
    margin-right: 15px;
  `}

  span {
    background: #223c7e;
    padding: 4px 10px;
    color: #ffffff;
    display: inline-block;
    min-width: 75px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-align: center;
  }
`;
const Content = styled.div`
  flex: 1 1 auto;
`;

const DottedLine = styled.div`
  width: 1px;
  height: 100%;
  position: absolute;
  top: 50px;
  bottom: 50px;
  left: 40px;
  border-left: 1px dashed #cbd1e2;
  &:nth-child(even) {
    &:before {
      background: #223c7e;
    }
  }

  &:before {
    content: "";
    width: 8px;
    height: 8px;
    display: block;
    position: absolute;
    border-radius: 100%;
    background: #223c7e;
    margin-left: -0.1px;
    top: 38%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
`;

const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 396px;
  z-index: 1;
  .gatsby-image-wrapper {
    z-index: 2;
  }
`;
const TrustedRentalWrapper = styled.div`
  display: flex;
  margin-left: -35px;
  margin-right: -35px;
  flex-wrap: wrap;
  @media (max-width: 1279px) {
    margin-left: -15px;
    margin-right: -15px;
  }
  ${BreakpointDown.sm` 
    flex-wrap:wrap;
  `}
  p {
    & + p {
      margin-top: 30px;
    }
  }
`;
const TrustedRentalLeft = styled.div`
  flex: 1 1 50%;
  max-width: 50%;
  padding-left: 35px;
  padding-right: 35px;
  @media (max-width: 1279px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  ${BreakpointDown.sm` 
     flex:1 1 100%;
     max-width:100%;
  `}
`;
const TrustedRentalRight = styled.div`
  flex: 1 1 50%;
  max-width: 50%;
  padding-left: 35px;
  padding-right: 35px;
  @media (max-width: 1279px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  ${BreakpointDown.sm` 
     flex:1 1 100%;
     max-width:100%;
  `}
`;
const TrustedRentalRightInner = styled.div`
  display: flex;
  margin-left: -8px;
  margin-right: -8px;
  align-items: center;
    top:100px;
    position:sticky;
  .img-wrapper {
    & + .img-wrapper {
      margin-top: 8px;
    }
  }
`;
const LeftItem = styled.div`
  flex: 1;
  padding-right: 8px;
  padding-right: 8px;
  position: relative;
`;
const RightItem = styled.div`
  flex: 1;
  padding-right: 8px;
  padding-right: 8px;
`;

const MarcusFounder = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -35px;
  margin-right: -35px;
  margin-top: 40px;
  align-items: center;
  @media (max-width: 1279px) {
    margin-left: -15px;
    margin-right: -15px;
  }
  ${BreakpointDown.lg`
   flex-direction:column;
   justify-content:center;
 `}
`;
const MarcusFounderLeft = styled.div`
  flex: 1 1 35%;
  max-width: 35%;
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width: 1279px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  ${BreakpointDown.lg`
   flex:1 1 100%;
   max-width:100%;
   width:100%;
   .img-wrapper{
     margin-left:auto;
     margin-right:auto;
     max-width:200px;
   }
 `}
`;

const MarcusFounderRight = styled.div`
  flex: 1 1 65%;
  max-width: 65%;
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width: 1279px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  ${BreakpointDown.lg`
   flex:1 1 100%;
   max-width:100%;
 `}
`;

const AboutMarcusFounder = styled.div`
  ${BreakpointDown.lg`
  text-align:center;
  margin-top:20px;
`}
`;
const Founder = styled.div`
  font-size: 22px;
  color: #f36224;
  font-weight: 600;
  position: relative;
  &:after {
    content: "";
    width: 25%;
    height: 2px;
    background: #f36224;
    vertical-align: middle;
    display: inline-block;
    margin-left: 10px;
  }
`;
const MarcusFounderTitle = styled.div`
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
`;
const MarcusFounderContent = styled.div`
  margin-top: 20px;
  ${BreakpointDown.lg`
  margin-top:8px;
 `}
`;

const SocialList = styled.div`
  display: flex;
  margin-top: 25px;
  align-items: center;
  ${BreakpointDown.lg`
    justify-content: center;
 `}
`;
const SocialListItem = styled.div`
  line-height: 1;
  align-self: center;
  svg {
    transition: 0.4s all ease;
  }
  &:hover {
    svg {
      fill: #223c7e;
    }
  }
  svg {
    width: 24px;
    height: 24px;
    fill: #cbd1e2;
  }
  & + & {
    margin-left: 20px;
  }
`;
const MarcusTeamWrapper = styled.div`
  margin-top: 20px;
`;

const MarcusTeam = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
`;

const MarcusTeamItem = styled.div`
  flex: 1 1 33.3333%;
  max-width: 33.3333%;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.lg`
    flex:1 1 50%;
    max-width:50%; 
  `}
  ${BreakpointDown.sm`
    flex:1 1 100%;
    max-width:100%; 
  `}
  &:nth-child(even) {
    .team-card {
      background: #fff5f0;
      border: 1px solid #fbdccd;
    }
  }
`;
const MarcusTeamCard = styled.div`
  border: 1px solid #ebf0ff;
  background: #f9fafc;
  padding: 30px;
  margin-top: 200px;
  min-height: calc(100% - 200px);
  ${BreakpointDown.lg`
     padding:20px 10px 0;
  `}
  ${BreakpointDown.sm`
    margin-top:170px;
  `}
  .img-wrapper {
    margin-top: -150px;
  }
`;
const MarcusTeamItemRole = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #f36224;
  margin-bottom: 4px;
`;
const MarcusTeamDetail = styled.div`
  margin-top: 32px;
`;

const MarcusTeamMember = styled.div`
  font-size: 26px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
`;

const BestQualityRental = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 60px;
  align-items: center;
  ${BreakpointDown.lg`
   flex-direction:column;
   .img-wrapper{
      max-width:250px;
      margin-left:auto;
      margin-right:auto;
    }
    text-align:center;
  `}
  &:nth-child(odd) {
    flex-direction: row-reverse;
  }
  .btn {
    padding-left: 0;
  }
`;
const BestQualityRentalLeft = styled.div`
  flex: 1 1 30%;
  max-width: 30%;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.lg`
   width:100%;
   flex: 1 1 100%;
   max-width:100%;
  `}
`;
const BestQualityRentalRight = styled.div`
  flex: 1 1 70%;
  max-width: 70%;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.lg`
   width:100%;
   flex: 1 1 100%;
   max-width:100%;
  `}
`;
const BestQualityHeading = styled.div`
  font-size: 34px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
  text-transform: capitalize;
  ${BreakpointDown.lg`
   margin-top:20px;
  `}
`;

function AboutUsPage(queryData) {
  let pageData = queryData.data.contentfulAboutUsPage;
  let LeftImg = [];
  let RightImg = [];
  pageData.marcusFamilySection.features.map((item, index) => {
    return index % 2 ? LeftImg.push(item) : RightImg.push(item);
  });

  return (
    <Layout pageName="about-us">
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription.metaDescription}
      />
      <Section
        bgColor="rgba(203, 209, 226, 0.55)"
        pt="180px"
        xpt="80px"
        pb="100px"
        xpb="90px"
      >
        <BreadCrumb top="75" xtop="5px">
          <div className="container">
            <Link to="/">
              <HomeIcon />
              <ArrowRight />
              </Link>
              <Link  className="line-after-heading">
              Our Story
            </Link>
          </div>
        </BreadCrumb>
        <TopWrapper>
          <PageHeaiding>{pageData.pageHeading}</PageHeaiding>
          <CardWrapper>
            {pageData.orgLogos.map((item) => {
              return (
                <CardWrapperItem>
                  <PaymentCard>
                    <CardWrapperItemImg>
                      <Img fluid={item.fluid} alt={item.title} />
                    </CardWrapperItemImg>
                  </PaymentCard>
                </CardWrapperItem>
              );
            })}
          </CardWrapper>
        </TopWrapper>
      </Section>
      <GradientWrapper>
        <Section
          pt="200px"
          pb="100px"
          xpt="100px"
          xpb="60px"
          mpt="40px"
          mpb="40px"
          bgColor="transparent"
        >
          <div className="container">
            <HeaidingImg>
              <Image name="history-text.png" alt="Marcus History" />
            </HeaidingImg>
            <SubTitle
              dangerouslySetInnerHTML={{
                __html:
                  pageData.marcusTimeline.contentText.childMarkdownRemark.html,
              }}
            />
            <MarcusHistory>
              <HistoryContentWrapper>
                <HistoryTimeLine>
                  {pageData.marcusTimeline.features.map((item, index) => {
                    return (
                      <TimeLineItem>
                        <Date>
                          <span>{item.titleText}</span>
                        </Date>
                        <Content
                          dangerouslySetInnerHTML={{
                            __html:
                              item.descriptionText.childMarkdownRemark.html,
                          }}
                        />
                        {pageData.marcusTimeline.features.length - 1 !==
                          index && <DottedLine />}
                      </TimeLineItem>
                    );
                  })}
                </HistoryTimeLine>
              </HistoryContentWrapper>
              <HistoryImgWrapper>
                <ImgWrapper className="img-wrapper">
                  <Img
                    fluid={pageData.marcusTimeline.sectionImage.fluid}
                    alt={pageData.marcusTimeline.sectionImage.title}
                  />
                  <DotGradiantCircleBototmLeft width="120px" height="120px" />
                </ImgWrapper>
              </HistoryImgWrapper>
            </MarcusHistory>
            <MarcusHistory>
              <HistoryContentWrapper
                dangerouslySetInnerHTML={{
                  __html:
                    pageData.marcusHistory.contentText.childMarkdownRemark.html,
                }}
              />
              <HistoryImgWrapper>
                <ImgWrapper className="img-wrapper">
                  <Img
                    fluid={pageData.marcusHistory.sectionImage.fluid}
                    alt={pageData.marcusHistory.sectionImage.title}
                  />
                  <DotGradiantCircleBototmRight width="120px" height="120px" />
                </ImgWrapper>
              </HistoryImgWrapper>
            </MarcusHistory>
          </div>
        </Section>
      </GradientWrapper>
      <Section
        pt="100px"
        pb="100px"
        xpt="60px"
        xpb="60px"
        mpt="40px"
        mpb="40px"
      >
        <div className="container">
          <TrustedRentalWrapper>
            <TrustedRentalLeft>
              <SectionTitle textAlign="left" mb="5px">
                {pageData.marcusFamilySection.headingText}
              </SectionTitle>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    pageData.marcusFamilySection.contentText.childMarkdownRemark
                      .html,
                }}
              />
            </TrustedRentalLeft>
            <TrustedRentalRight>
              <TrustedRentalRightInner>
                <LeftItem>
                  {LeftImg.length &&
                    LeftImg.map((item) => {
                      return (
                        <ImgWrapper className="img-wrapper">
                          <Img
                            fluid={item.image.fluid}
                            alt={item.image.title}
                          />
                        </ImgWrapper>
                      );
                    })}
                  <DotGradiantCircleTopRight width="80px" height="120px" />
                </LeftItem>
                <RightItem>
                  {RightImg.length &&
                    RightImg.map((item) => {
                      return (
                        <ImgWrapper className="img-wrapper">
                          <Img
                            fluid={item.image.fluid}
                            alt={item.image.title}
                          />
                        </ImgWrapper>
                      );
                    })}
                </RightItem>
              </TrustedRentalRightInner>
            </TrustedRentalRight>
          </TrustedRentalWrapper>
        </div>
        <BorderBox />
      </Section>

      <Section
        pt="100px"
        pb="100px"
        xpt="60px"
        xpb="60px"
        mpt="40px"
        mpb="40px"
      >
        <div className="container">
          <SectionTitle textAlign="center">
            {pageData.marcusTeamSection.subHeading}
          </SectionTitle>
          <MarcusFounder>
            <MarcusFounderLeft>
              <ImgWrapper className="img-wrapper">
                <Img
                  fluid={pageData.marcusTeamSection.sectionImage.fluid}
                  alt={pageData.marcusTeamSection.sectionImage.title}
                />
                <DotGradiantCircleTopRight1 width="120px" height="140px" />
              </ImgWrapper>
            </MarcusFounderLeft>
            <MarcusFounderRight>
              <AboutMarcusFounder>
                <MarcusFounderTitle>
                  {pageData.marcusTeamSection.headingText}
                </MarcusFounderTitle>
                <Founder>{pageData.marcusTeamSection.labelText}</Founder>
                <MarcusFounderContent
                  dangerouslySetInnerHTML={{
                    __html:
                      pageData.marcusTeamSection.contentText.childMarkdownRemark
                        .html,
                  }}
                />
                <SocialList>
                  <SocialListItem>
                  <a
                      href={pageData.marcusTeamSection.buttonUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TwitterIcon />
                    </a>
                  </SocialListItem>
                  <SocialListItem>
                 <a
                    href={pageData.marcusTeamSection.linkText}
                    target="_blank"
                    rel="noreferrer"
                  >
                   
                      <LinkedInIcon />
                    </a>
                  </SocialListItem>
                  <SocialListItem>
                  <a
                      href={pageData.marcusTeamSection.buttonText}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <EnvelopIconSingleColor />
                    </a>
                  </SocialListItem>
                </SocialList>
              </AboutMarcusFounder>
            </MarcusFounderRight>
          </MarcusFounder>
          <MarcusTeamWrapper>
            <MarcusTeam>
              {pageData.marcusTeamSection.features.map((item, index) => (
                <MarcusTeamItem key={index}>
                  <MarcusTeamCard className="team-card">
                    <ImgWrapper className="img-wrapper">
                      <Img fluid={item.image.fluid} alt={item.image.title} />
                    </ImgWrapper>
                    <MarcusTeamDetail>
                      <MarcusTeamMember>{item.titleText}</MarcusTeamMember>
                      <MarcusTeamItemRole>{item.buttonText}</MarcusTeamItemRole>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.descriptionText.childMarkdownRemark.html,
                        }}
                      />
                    </MarcusTeamDetail>
                  </MarcusTeamCard>
                </MarcusTeamItem>
              ))}
            </MarcusTeam>
          </MarcusTeamWrapper>
        </div>
      </Section>
      <GradientWrapper>
        <BorderTop
          pt="100px"
          pb="180px"
          bgColor="transparent"
          xpt="60px"
          xpb="120px"
          mpt="40px"
        >
          <div className="container">
            <SectionTitle textAlign="center" TextAlign="left">
              {pageData.marcusOrgSection.headingText}
            </SectionTitle>
            {pageData.marcusOrgSection.features.map((item, index) => {
              return (
                <BestQualityRental>
                  <BestQualityRentalLeft>
                    <ImgWrapper className="img-wrapper">
                      <Img fluid={item.image.fluid} alt={item.image.title} />
                    </ImgWrapper>
                  </BestQualityRentalLeft>
                  <BestQualityRentalRight>
                    <BestQualityHeading className="line-after-heading">
                      {item.titleText}
                    </BestQualityHeading>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.descriptionText.childMarkdownRemark.html,
                      }}
                    />
                    <Link to={item.buttonUrl}>
                      <SecondaryLinkButton
                        text={item.buttonText}
                        icon={<ArrowLineIcon />}
                      />
                    </Link>
                  </BestQualityRentalRight>
                </BestQualityRental>
              );
            })}
          </div>
        </BorderTop>
      </GradientWrapper>
    </Layout>
  );
}
export default AboutUsPage;

export const query = graphql`
  query AboutUsPage {
    contentfulAboutUsPage {
      pageUrl
      metaTitle
      metaDescription {
        metaDescription
      }
      pageHeading
      orgLogos {
        title
        fluid {
          aspectRatio
          base64
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      marcusTimeline {
        contentText {
          childMarkdownRemark {
            html
          }
        }
        sectionImage {
          title
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        features {
          titleText
          descriptionText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      marcusHistory {
        contentText {
          childMarkdownRemark {
            html
          }
        }
        sectionImage {
          title
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      marcusFamilySection {
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        features {
          image {
            fluid {
              aspectRatio
              base64
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
      marcusTeamSection {
        labelText
        subHeading
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        sectionImage {
          title
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        buttonText
        buttonUrl
        linkText
        features {
          titleText
          descriptionText {
            childMarkdownRemark {
              html
            }
          }
          image {
            title
            fluid {
              aspectRatio
              base64
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          buttonText
        }
      }
      marcusOrgSection {
        headingText
        features {
          titleText
          descriptionText {
            childMarkdownRemark {
              html
            }
          }
          image {
            title
            fluid {
              aspectRatio
              base64
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          buttonText
          buttonUrl
        }
      }
    }
  }
`;
