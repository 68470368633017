import React from "react";
import styled from "styled-components";
import BreakpointDown from "../Media/BreakpointDown";

import {
  OnlinePaymentIcon,
  MakePaymentIcon,
  PostalPaymentIcon,
  ApplicationIocn,
  PaperlessContractIcon,
  PaymentOption,
  DedicatedTeamIcon,
  LogoIcon,
} from "../Icons";

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 50px #0000000f;
  border: 1px solid #cbd1e2;
  border-radius: 5px;
  position: relative;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  height: 100%;
  @media (max-width: 1440px) {
    padding: 20px;
  }
  ${BreakpointDown.xl`
    padding:15px;
  `}

  &:before {
    position: absolute;
    left: 5px;
    top: 5px;
    content: "";
    width: 0;
    height: 0;
    border-top: 20px solid #ebf0ff;
    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #ebf0ff;
  }
`;
const Icon = styled.div`
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  svg {
    width: 100%;
  }
`;
const Title = styled.div`
  color: #121316;
  font-size: 19px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 5px;
  @media (max-width: 1440px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const PaymentCard = ({ title, name, children }) => {
  let PaymentIcon = "";
  switch (name) {
    case "online payment":
      PaymentIcon = <OnlinePaymentIcon />;
      break;

    case "make payment":
      PaymentIcon = <MakePaymentIcon />;
      break;

    case "postal payment":
      PaymentIcon = <PostalPaymentIcon />;
      break;

    case `online application`:
      PaymentIcon = <ApplicationIocn />;
      break;

    case "paperless contracts":
      PaymentIcon = <PaperlessContractIcon />;
      break;

    case "dedicated team":
      PaymentIcon = <DedicatedTeamIcon />;
      break;

    case "finance payment":
      PaymentIcon = <PaymentOption />;
      break;

    case "home":
      PaymentIcon = <LogoIcon />;
      break;

    default:
      break;
  }
  return (
    <Card>
      {PaymentIcon && <Icon>{PaymentIcon}</Icon>}
      {title && <Title>{title}</Title>}
      {children && children}
    </Card>
  );
};

export default PaymentCard;
